
define(['$window', 'app', '$console'], ($window, app, $console) => {
  const storeLocatorSearch = () => {
    const component = {};

    const _config = {
      locator: '[data-js-element=locator]',
      locatorCard: '[data-js-element=locatorCard]',
      searchForm: '[data-js-element=locatorSearch_form]',
      searchInput: '[data-search]',
      searchComplete: 'locatorSearch-done',
      searchShow: 'locator-show',
      autoCompleteId: 'store-locate-autocomplete',
      locatorSearch: '.locatorSearch_searchBar',
      storeLocatorDropdown: '.storeLocatorDropdown',
      storeLocatorDropdownSuggestion: '.storeLocatorDropdown_suggestion',
      show: 'show',
      locatorChannel: '_locator',
      locatorCardChannel: '_locatorCards'
    };

    const _init = (element) => {
      component.element = element;
      component.locator = document.querySelector(component.config.locator);
      component.searchForm = component.element.querySelector(component.config.searchForm);
      component.locatorSearch = component.element.querySelector(component.config.locatorSearch);
      component.locatorSearch.addEventListener('input', component.onSearch);
      component.searchForm.addEventListener('submit', (event) => { component.submitLocation(event) });
    };

    const _onSearch = () => {
      if(component.locatorSearch.value.length > 0) {
        app.ajax.get({
          url: `/recommendedLocations.lookup?location=${component.locatorSearch.value}&stringTemplatePath=components/locator/partials/locatorSearch/storeLocatorDropdown`,
          success: component.searchSuccessHandler,
          error: () => { $console.error('failed to get recommended locations') }
        });
      }
      else {
        component.removeDropdown();
      }
    };

    const _searchSuccessHandler = (response) => {
      if(response) {
        component.storeLocatorDropdown = component.element.querySelector(component.config.storeLocatorDropdown);
        component.storeLocatorDropdown && component.element.removeChild(component.storeLocatorDropdown);
        component.element.insertAdjacentHTML('beforeend', response);

        component.storeLocatorDropdownSuggestions =
            component.element.querySelectorAll(component.config.storeLocatorDropdownSuggestion);
        component.storeLocatorDropdownSuggestions && component.storeLocatorDropdownSuggestions.forEach(suggestion => {
          suggestion.addEventListener('click', () => { component.showNearByStores(suggestion) });
        });
      }
    };

    const _submitLocation = (event) => {
      event.preventDefault();
      if(component.locatorSearch.value.length > 0) {
        component.firstLocationSuggestion =
          component.element.querySelector(component.config.storeLocatorDropdownSuggestion);
        component.showNearByStores(component.firstLocationSuggestion);
        component.removeDropdown();
      }
    };

    const _showNearByStores = (suggestion) => {
      if (suggestion !== null && suggestion.hasAttribute('data-longitude') && suggestion.hasAttribute('data-latitude')) {
        const longitude = suggestion.getAttribute('data-longitude');
        const latitude = suggestion.getAttribute('data-latitude');

        if (longitude === null || latitude === null || isNaN(longitude) || isNaN(latitude)) {
          return;
        }

        app.ajax.get({
          url: `/getNearestStoresLocatorCardXHR.lookup?lon=${longitude}&lat=${latitude}`,
          success: component.onSuccess,
          error: component.onError
        });
      }

      component.removeDropdown();
    };

    const _onSuccess = (response) => {
      document.querySelector(component.config.locatorCard).outerHTML = response;
      component.addClasses();
    };

    const _onError = () => {
      console.error('Sorry, something is wrong');
    };

    const _addClasses = () => {
      app.element.addClass(component.config.searchComplete, component.element);
      app.element.addClass(component.config.searchShow, component.locator);
      app.publish(component.config.locatorChannel);
      app.publish(component.config.locatorCardChannel);
    };

    const _removeDropdown = () => {
      component.storeLocatorDropdown = component.element.querySelector(component.config.storeLocatorDropdown);
      component.storeLocatorDropdown && component.element.removeChild(component.storeLocatorDropdown);
    };

    component.config = _config;
    component.init = _init;
    component.onSearch = _onSearch;
    component.submitLocation = _submitLocation;
    component.showNearByStores = _showNearByStores;
    component.onSuccess = _onSuccess;
    component.onError = _onError;
    component.addClasses = _addClasses;
    component.removeDropdown = _removeDropdown;
    component.searchSuccessHandler = _searchSuccessHandler;

    return component;
  };

  return storeLocatorSearch;
});
